import React, { useEffect } from 'react';

import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Search from './screens/Search';
import LoginScreen from './screens/login';
import Course from './screens/Course';
import Documents from './screens/Documents';
import Main from './screens/Main';
import Subjects from './screens/Subjects';
import AddCourse from './screens/AddCourse';
import AddSubject from './screens/AddSubject';
import UploadNotes from './screens/UploadNotes';
import SelectNotes from './screens/SelectNotes';
import NavScrollExample from './components/Navbar';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import Checkout from './screens/Checkout';
import PaymentPage from './screens/PaymentPage';
import PaymentSuccess from './components/PaymentSuccess';
import MyLearnings from './screens/MyLearnings';
import TransactionDetails from './screens/TransactionDetails';
import UserAccessControl from './components/GrantAccess';
import useSessionTimeout from './components/useSessionTimeout';

// Define routes
const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/login',
    element: <LoginScreen />,
  },
  {
    path: '/Courses',
    element: <Course />,
  },
  {
    path: '/docType/:subjectName/:year/:course/:type',
    element: <Documents />,
  },
  {
    path: '/documents/:subjectName/:year/:course',
    element: <SelectNotes />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/subject/:courseName/:year',
    element: <Subjects />,
  },
  {
    path: '/AddCourse',
    element: <AddCourse />,
  },
  {
    path: '/uploadNotes/:subjectName/:year/:course',
    element: <UploadNotes />,
  },
  {
    path: '/addSubject/:courseParam/:year',
    element: <AddSubject />, // Updated path to include 'year'
  },
  {
    path: '/subject/:subjectName',
    element: <Search />, 
  },
  {
    path: '/checkOut',
    element: <Checkout />, 
  },
  {
    path: '/pay',
    element: <PaymentPage />, 
  },
  {
    path: '/payment-succes',
    element: <PaymentSuccess />, 
  },
      {
    path: '/paymentStatus/:orderId',
    element: <TransactionDetails/>,
  },
  {
    path: '/myCourses',
    element: <MyLearnings />,
  },
  {
    path: '/granAccessControl',
    element: <UserAccessControl/>,
  },
  {
    path: '*', // Catch-all route for undefined paths
    element: <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>,
  },
]);

function App() {
  useSessionTimeout(6000);

  useEffect(() => {
    const disableContextMenu = (event) => event.preventDefault();
    const disablePrintShortcut = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault(); // Prevent Ctrl + P
        alert('Printing is disabled on this page.');
      }
    };

    document.addEventListener('contextmenu', disableContextMenu);
    document.addEventListener('keydown', disablePrintShortcut);
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('keydown', disablePrintShortcut);
    };
  }, []);


  return (
    <div>
      <NavScrollExample />
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;